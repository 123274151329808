import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/utils/seo"
import HeaderSimple from "../components/headers/HeaderSimple"

const NotFoundPage = () => {
  const headerData = {
    h1: "Error 404, página no encontrada",
    mainHeading: "Algo ha salido mal y la página que buscas no existe.",
    subHeading: "",
    ctaLink: {
      text: "Página de inicio",
      to: "/",
      title: "Volver a inicio",
    },
  }
  return (
    <Layout>
      <SEO
        title="Página no encontrada"
        description="Error 404 página no encontrada"
        socialImg=""
        robots="noindex,nofollow"
        canonical="https://www.vipclass.taxi/404/"
      />
      <HeaderSimple headerData={headerData} />
      <main></main>
    </Layout>
  )
}

export default NotFoundPage
